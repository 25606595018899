import React from "react"
import MainTemplate from 'templates/MainTemplate'
import styled from 'styled-components';
import SEO from 'components/SEO/seo'
import Quides from 'components/FreeMaterials/Quides/Quides';
import FreeMaterial from 'components/FreeMaterials/Free/Free';
import Journal13 from 'components/FreeMaterials/Journals/Journal';
// import Multibook from 'components/FreeMaterials/Multibook/Multibook';
import Song from 'components/FreeMaterials/Song/Song';
import Posters from "../components/FreeMaterials/Posters/Posters";

const StyledFreeMaterials = styled.div`
width: 100%;
display: flex;
justify-content: center;
padding-top: 20px;
background-color: ${({theme}) => theme.body};
font-family: ${({theme}) => theme.font.family.Montserrat};
${({theme}) => theme.media.desktop} {
width: 100%;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
justify-content: center;
padding-top: 50px;
}`

const StyleWrapper = styled.div`
width: 100%;
max-width: 1100px;
height: auto;
${({theme}) => theme.media.desktop} {
width: 70%;
min-width:900px;
max-width: 1100px;
height: auto;
}
`

const StyledInner = styled.div`
display:flex;
flex-direction: column;
overflow: hidden;
padding: 15px;
padding-bottom: 0;
border: 1px solid rgba(0, 0, 0, 0.15);
background-color: rgba(255, 255, 255, 0.7);
margin-bottom: 20px;
${({theme}) => theme.media.desktop} {
display:flex;
flex-direction: row;
flex: 1;
border: 1px solid rgba(0, 0, 0, 0.15);
background-color: rgba(255, 255, 255, 0.7);
margin-bottom: 20px;
}`


const StyledTitile = styled.div`
padding: 6px 18px;
background-color: ${({isGreen}) => isGreen ? 'rgba(120,200,40,1);' : 'rgba(0,160,240,1)'};
font-weight: 400;
color: white;
`


const FreeMaterials = () => (
<MainTemplate>
<SEO title="Bezpłatne materiały" />
    <StyledFreeMaterials>
        <StyleWrapper>

          <StyledTitile><h1><strong>Poradniki metodyczne</strong></h1></StyledTitile>
            <StyledInner>
              <Quides category="Przedszkole"/>
              <Quides category="Szkoła Podstawowa"/>
              <Quides category="Liceum/Technikum"/>
            </StyledInner>

          <StyledTitile><h1><strong>Roczny rozkład materiału</strong></h1></StyledTitile>
            <StyledInner>
              <FreeMaterial category="Przedszkole"/>
              <FreeMaterial category="Szkoła Podstawowa"/>
              <FreeMaterial category="Liceum/Technikum"/>
            </StyledInner>

          <StyledTitile isGreen id="dziennik"><h1><strong>Dziennik Elektroniczny</strong></h1></StyledTitile>
            <StyledInner>
              <Journal13 category="Szkoła Podstawowa kl.1-3"/>
              <Journal13 category="Szkoła Podstawowa kl.4-8"/>
              <Journal13 category="Liceum/Technikum"/>
            </StyledInner>

            {/* <StyledTitile isGreen id="mulitibook"><h1><strong>E-podręczniki i Multibooki</strong></h1></StyledTitile>
            <StyledInner>
              <Multibook/>
            </StyledInner> */}

            <StyledTitile isGreen><h1><strong>Piosenki</strong></h1></StyledTitile>
            <StyledInner>
              <Song/>
            </StyledInner>

          <StyledTitile><h1><strong>Plakaty</strong></h1></StyledTitile>
            <StyledInner>
              <Posters/>
            </StyledInner>

        </StyleWrapper>
    </StyledFreeMaterials>

</MainTemplate>
)

export default FreeMaterials
